










































































































































































































































































































































































































import {
    apiShopLists,
    apiShopSwitchStatus,
    apiShopDelete,
    apiChangeRemark,
    apiGetappListsNopage,
    apiGetCustomerListsNopage,
    apiSetShopPackge,
} from "@/api/shop";
import { apiGoodsPackgeListsNopage } from "@/api/goods";
import { apiAuth } from "@/api/app";

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import PlatformPermission from "./components/platform-permission.vue";

@Component({
    components: {
        lsPagination,
        ExportData,
        LsDialog,
        PlatformPermission,
    },
})
export default class ShopList extends Vue {
    /** S Data **/

    apiShopLists = apiShopLists;

    pager: RequestPaging = new RequestPaging();
    business_id = this.$route.query.business_id;
    searchObj: Object | any = {
        status: "",
        shop_info: "",
        create_time: "",
        app_id: 11,
        business_id: this.business_id,
    };

    dialogVisible = false;

    root: any = "";

    activeName: string = "";

    //应用列表
    applicationList: any[] = [];

    //运营包列表
    listsNopage: any[] = [];

    //客户列表

    options: any[] = [];

    build_form: any = {
        id: 0,
        packge_id: "", //状态
        self_operation_permit: 1, //是否允许商城自行上传商品
    };

    platformPermissionData: any = {
        show: false, //弹出数据
        title: "", //弹出标题
    };

    /** E Data **/

    /** S Methods **/

    @Watch("$route")
    routeParamsChanged(newParams: any, oldParams: any): void {
        if (newParams.path == "/supplier/lists") {
            this.getShopLists();
        }
    }

    // 获取商城列表
    getShopLists(page?: number) {
        page && (this.pager.page = page);
        this.pager.request({
            callback: apiShopLists,
            params: {
                ...this.searchObj,
            },
        });
    }
    getFW(fw: any) {
        let txt = "";
        if (!fw || fw.length === 0) {
            return txt;
        }
        const jyfw: any[] = ["实物商品", "券核销", "同城配送", "到店自提"];
        fw.map((v: any, k: Number) => {
            let i = Number(v) - 1;
            txt = jyfw[i] + ", " + txt;
        });
        return txt;
    }

    // 修改商城状态
    async shopSwitchStatusEdit(status: number, id: number): Promise<void> {
        await apiShopSwitchStatus({ id });
        this.getShopLists();
    }

    async changeRemarks(text: string, id: number): Promise<void> {
        await apiChangeRemark({ id: id, remark: text });
        this.getShopLists();
    }

    // 删除商城
    async onShopDelete(row: any): Promise<void> {
        await apiShopDelete({ id: row.id });
        this.getShopLists();
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            if (key != "app_id") this.$set(this.searchObj, key, "");
        });
        this.getShopLists();
    }

    //获取管理员信息
    getAadmin(): void {
        apiAuth().then((res) => {
            this.root = res.root;
            console.log(res);
        });
        console.log(this.root);
    }

    // 去编辑商城
    toShopEdit(id: number | any): void {
        this.$router.push({
            path: "/supplier/lists_detail",
            query: {
                id: id,
                appid: this.searchObj.app_id,
            },
        });
    }

    // 修改超级管理员
    toSuperAdminUpdate(id: number | any): void {
        this.$router.push({
            path: "/supplier/modify_account",
            query: {
                id: id,
            },
        });
    }

    // 去商家后台
    toShop(url: any, status: number) {
        if (status == 0) return this.$message.error("该商城暂未营业中");

        if (url.indexOf("https://") === 0) window.open(url);
        else if (url.indexOf("http://") === 0) window.open(url);
        else window.open("http://" + url);
    }
    handleClick(tab: object | any, event: object) {
        this.searchObj.app_id = tab.name;
        this.getShopLists();
        this.getCustomerListsNopage();
    }

    //获取应用权限列表
    async getApplicationList() {
        const res: any = await apiGetappListsNopage({});
        this.applicationList = res;
        if (res) {
            this.activeName = this.applicationList[1].appid.toString();
            this.searchObj.app_id = this.applicationList[1].appid.toString();
            // this.searchObj.app_id[0] = this.applicationList[1].appid.toString();
            // this.searchObj.app_id[1] = this.applicationList[2].appid.toString();
            this.getShopLists();
            this.getCustomerListsNopage();
        }
    }

    //获取客户
    async getCustomerListsNopage(): Promise<void> {
        const res = await apiGetCustomerListsNopage({
            app_id: this.searchObj.app_id,
        });
        this.options = res;
    }

    // 打开运用包选择弹窗
    openShopPackge(obj: any) {
        this.dialogVisible = true;

        this.listsNopage.length <= 0 ? this.goodsPackgeListsNopage("") : "";
        this.build_form = {
            id: obj.id,
            packge_id: obj.packge_id, //状态
            self_operation_permit: +obj.self_operation_permit, //是否允许商城自行上传商品
        };
    }

    //选择运营包 保存
    async setShopPackge() {
        const res = await apiSetShopPackge({
            ...this.build_form,
        });
        if (res) {
            this.getApplicationList();
            this.dialogVisible = false;
            this.build_form = {
                id: 0,
                packge_id: "", //状态
                self_operation_permit: 1, //是否允许商城自行上传商品
            };
        }
    }

    //获取关联id列表
    async goodsPackgeListsNopage(query: string): Promise<void> {
        const res = await apiGoodsPackgeListsNopage({ packge_name: query });
        this.listsNopage = res;
    }

    // 弹出权限管理
    handlePlatformPermissionDrawer(item: any) {
        this.$store.commit("setStoreId", item.id);
        localStorage.setItem("supplierId", item.id);
        localStorage.setItem("api_type", "supplier");
        this.platformPermissionData.show = true;
        this.platformPermissionData.title = item.name;
    }

    //关闭管理中心
    platformPermissionClose(done: any) {
        done();
        this.$store.commit("setStoreId", null);
    }
    /** E Methods **/

    /** S ods **/
    created() {
        this.getAadmin();
        this.getApplicationList();
    }
}
